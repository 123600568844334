import * as Constants from "./constants";

export class StorageHelper {
    static setRole(role) {
        localStorage.setItem(Constants.LS_USER_KEY, role);
    }

    static getRole() {
        return localStorage.getItem(Constants.LS_USER_KEY);
    }

    static setToken(token) {
        localStorage.setItem(Constants.LS_TOKEN_KEY, token);
    }

    static getToken() {
        return localStorage.getItem(Constants.LS_TOKEN_KEY);
    }

    static removeToken() {
        return localStorage.removeItem(Constants.LS_TOKEN_KEY);
    }

    static removeRole() {
        return localStorage.removeItem(Constants.LS_USER_KEY);
    }

}
