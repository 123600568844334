import { createRouter, createWebHistory } from "vue-router"
import {StorageHelper} from "@/common/storageHelper";
import {USER_ROLE} from "@/common/constants";


const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/Login.vue")
    },
    {
        path: "/areas",
        name: "areas",
        alias: "/",
        meta: { requiresAuth: true },
        component: () => import("../views/SelectArea.vue")
    },

    {
        path: "/stats",
        name: "stats",
        meta: { requiresAuth: true },
        component: () => import("../views/StatsAreas.vue")
    },

    {
        path: "/reset",
        name: "reset",
        meta: { requiresAuth: true },
        component: () => import("../views/ResetAreas.vue")
    },
    {
        path: "/areas/:id",
        name: "area-detail",
        meta: { requiresAuth: true },
        component: () => import("../views/AreaMonitoring.vue")
    },
];


const routerManager = createRouter({
    mode: "history",
    history : createWebHistory(),
    routes
})

routerManager.beforeEach((to, from, next) => {
    const token = StorageHelper.getToken()
    const role  = StorageHelper.getRole()
    if (to.meta.requiresAuth) {

        if (token && role) {
            if (to.path === "/") {
                switch (role) {
                    case USER_ROLE.OPERATOR:
                        next({name : "areas"})
                        break;
                    case USER_ROLE.RESET:
                        next({name : "reset"})
                        break;
                    case USER_ROLE.STATS:
                        next({name : "stats"})
                        break;

                }
            } else {
                next();
            }
        } else {
            next({ name: "login" });
        }
    } else {
        if (to.name === "login" && token && role) {
            switch (role) {
                case USER_ROLE.OPERATOR:
                    next({name : "areas"})
                    break;
                case USER_ROLE.RESET:
                    next({name : "reset"})
                    break;
                case USER_ROLE.STATS:
                    next({name : "stats"})
                    break;

            }
        } else {
            next();
        }
    }
});

export default routerManager;
