<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
}
</style>
