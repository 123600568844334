export const PREFIX_KEY = "sicurezza_";

// Local Storage KEY
export const LS_TOKEN_KEY = PREFIX_KEY + "token_user";
export const LS_REFRESH_TOKEN_KEY = PREFIX_KEY + "refresh_token_user";
export const LS_USER_KEY = PREFIX_KEY + "user_json";

export const BASE_URL_API = "https://security-api.neumann.abinsula.com/";
export const URL_API_USER_LOGIN = BASE_URL_API + "login/";
export const URL_API_USER_MONITORING = BASE_URL_API + "monitoring/";
export const URL_API_RESET = BASE_URL_API + "reset/";

export const AREAS = {
  AREA_A: "a",
  AREA_B: "b"
};

export const USER_ROLE = {
  RESET: "RE",
  OPERATOR: "OP",
  STATS: "ST",
};